
import { get, post } from '@/untils/js/axios.js'

// 砍价商品列表
export const haggle_list = params => get(`/bargain/goods`, params)

// 我的砍价商品列表
export const my_haggle = params => get(`/bargain/user/goods/sku`, params)

// 砍价商品详情
export const haggle_detail = (id,params) => get(`/bargain/goods/${id}`, params)

// 砍价商品SKU信息
export const goodsSku_detail = (id,params) => get(`/bargain/user/goods/sku/${id}`, params)

// 参加砍价
export const join_haggle = params => post(`/bargain/user/goods/sku`, params)

// 获取帮助列表
export const haggle_log = params => get(`/bargain/user/bargain/log`, params)

// 帮忙砍价
export const haggle_help = params => post(`/bargain/user/bargain/log`, params)

// 是否可以帮忙砍价
export const haggle_create = params => get(`/bargain/user/bargain/log/create`, params)


// 直播 砍价商品列表
export const live_list = params => get(`/live/goods`, params)

// 直播 砍价商品列表
export const my_join = params => get(`/live/user/join`, params)

// 直播 砍价商品详情
export const live_detail = (id, params) => get(`/live/goods/${id}`, params)

// 直播 参加砍价
export const jion_create = params => get(`/live/user/join/create`, params)

// 直播 参与详情
export const live_sku_detail = (id, params) => get(`/live/user/join/${id}`, params)

// 直播 参加砍价
export const join_live_haggle = params => post(`/live/user/join`, params)

// 直播 获取帮助列表
export const join_help_list = params => get(`/live/user/bargain/log`, params)

// 直播 帮忙砍价
export const live_haggle_help = params => post(`/live/user/bargain/log`, params)

// 是否可以帮忙砍价
export const live_haggle_create = params => get(`/live/user/bargain/log/create`, params)

// 接收邮箱
export const live_email = params => get(`/live/user/email`, params)

// 接收邮箱
export const add_email = params => post(`/live/user/email`, params)