/** 
 *   详情
 */
import { get,post } from '@/untils/js/axios.js'

// 获取商品详情信息
export const getGoodsInfo = (id,params) => get(`/goods/details/${id}`, params)

// 获取推荐商品
export const getDetailRemGoods = params => get(`/goods/recommend/details`, params)

// 获取热销商品
export const getHotGoods = (id,params) => get(`/goods/details-hot/${id}`, params)

// 获取SKU
export const getSku = (id,params) => get(`/goods/sku-list/${id}`,params)

// 获取评论
export const getComment = params => get(`/comment_list`, params)

// 获取商品主图BASE64
export const getImg = (id,params) => get(`/goods/share/${id}`, params)

// 判断是否登录
export const checkLogin = params => get(`/login/check-token`, params)

// 微信分享
export const weshare = params => post('/wechat/sharing' ,params)